import React from 'react';
import './End.scss';
import { FaTwitter, FaDiscord } from 'react-icons/fa';
import { images } from '../../constants'; 

const End = () => {
  return (
    <footer className="app__end">
      <div className="app__end-content">
        <div className="app__end-logo">
          <img src={images.logo} alt="Company Logo" />
        </div>

        <div className="app__end-check-this-out">
          <div className="app__end-title">Sections</div>
          <ul className="app__end-links">
            {['home', 'about', 'work', 'skills', 'contact'].map((item) => (
              <li key={item}>
                <a href={`#${item}`}>{item}</a>
              </li>
            ))}
          </ul>
        </div>

        <div className="app__end-socials">
          <div className="socials-title">Follow me</div>
          <a href="https://discord.com/users/775777178033324045" target="_blank" rel="noopener noreferrer">
            <FaDiscord />
          </a>
          <a href="https://twitter.com/notzin_" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
        </div>

        <div className="app__end-copyright">
          &copy; 2023 - 2024 Zin. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default End;
