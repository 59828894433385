import React from 'react';

const NavigationDots = ({ active }) => (
  <div className="app__navigation">
    {['home', 'about', 'work', 'skills', 'testimonial', 'contact'].map((item, index) => (
      <a
        href={`#${item}`}
        key={item + index}
        className="app__navigation-dot"
        style={{
          backgroundColor: active === item ? '#7338A0' : '',
          transition: 'background-color 0.3s ease',
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = '#7338A0')}
        onMouseLeave={(e) => {
          if (active !== item) e.target.style.backgroundColor = '';
        }}
      />
    ))}
  </div>
);

export default NavigationDots;
