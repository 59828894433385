import React from 'react';
import { NavigationDots } from '../components'; // Removed SocialMedia import

const AppWrap = (Component, idName, classNames) => function HOC() {
  return (
    <div id={idName} className={`app__container ${classNames}`}>
      {/* Inline style placeholder div to maintain space where SocialMedia was */}
      <div
        style={{
          width: '50px', // Adjust width to match SocialMedia space
          height: '100px', // Adjust height based on original SocialMedia height
          marginRight: '20px', // Optional: match the spacing as per your layout
        }}
      ></div>

      <div className="app__wrapper app__flex">
        <Component />

      </div>
      <NavigationDots active={idName} />
    </div>
  );
};

export default AppWrap;
